import { Link } from 'react-router-dom'

import Sponsors from '../components/Sponsors';

import '../page.scss'
import './StartPage.scss';

import posterThumbImage from '../assets/poster.png';
import flowerImage from '../assets/blomma.png';

function StartPage() {
    return (
        <div className="StartPage">
            <div className="pageItem">
                <div className="pageItem-header">
                    <h2>Välkomna till konstMajrundan under Skördefesten 2024</h2>
                </div>
                <div className="pageItem-body news-body">
                    <h3>Öppna Ateljeér under Skördfesten 2024:</h3>
                    <p>
                        <ul className="atelier-list">
                            <li>Birgitta Augustine i Karlevi</li>
                            <li>Göran Petersson i Sönnerborg</li>
                            <li>Ingela Beijer i Kvistorp</li>
                            <li>Tina Stellema i Sättra</li>
                            <li>Monica Alskans i Sättra</li>
                            <li>Ed Whittaker i Sättra</li>
                            <li>Roland Gross i Rälla tall</li>
                            <li>Helle Kvamme i Borgholm</li>
                            <li>Carl-Olof Damm i Borgholm</li>
                            <li>Anna Hertzberg/Holgersson i Borgholm</li>
                            <li>Susanne Nyholm Borgholm</li>
                            <li>Sixten Carlsson i Borgholm</li>
                            <li>Annika Liepa Borgholm</li>
                        </ul>
                    </p>
                    <p>
                        KonstMajrundans medlemar hittar du <Link to="/artists">här</Link>
                    </p>
                    <div><img className='flowerImage' src={flowerImage} alt='bild på blomma' /></div>
                </div>
            </div>
            <div className='pageItem'>
                <Sponsors />
            </div>
        </div>
    );
}

export default StartPage;
